module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ausgetauscht.de","short_name":"ausgetauscht.de","start_url":"/","background_color":"#ffffff","theme_color":"#e96101","display":"standalone","icon":"src/images/ausgetauscht-emblem.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6ce341bc47f884eafd9631b4577f9fa7"},
    },{
      plugin: require('../node_modules/gatsby-source-akcms-v2/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"be4398cd591c935a1f2e6aab547e8c682674bbb50548ad7b","apiBaseUrl":"https://api.cms.austauschkompass.de/api/v1"},
    },{
      plugin: require('../node_modules/gatsby-source-akratings-high-school/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://www.schueleraustausch.de/api/v2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"18","matomoUrl":"https://stats.schueleraustausch.net","siteUrl":"https://ausgetauscht.de"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
